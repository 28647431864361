import React, { useEffect } from "react";
import * as TrackingLib from "../libs/tracking";
// import "./TermsService.css";

export default function TermsService() {
    useEffect(() => {
        window.scrollTo(0, 0);
        TrackingLib.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className="TermsService pt-3 text-justify">
            <h3 className="text-center">GrowBevy - Terms of Service</h3>
            <p className="text-center">June 17th, 2020</p>
            <p>These Terms of Service (these "Terms") govern your access or use of our services, GrowBevy-operated websites and mobile applications (including growbevy.com and bhelp.com), the GrowBevy iOS and Android applications, and any other website or application operated by GrowBevy (collectively, the "Service"). In these Terms, "we," "us," "our," and "GrowBevy" refer to MergeCreative, LLC. d/b/a GrowBevy. These Terms include and hereby incorporate by reference GrowBevy's privacy policy available at https://growbevy.com/privacy-policy . Please read these Terms carefully before accessing or using the Service. By accessing or using the Service, you agree to comply with and be bound by these Terms. If you disagree with any part of these Terms, please do not access or use the Service.</p>
            <p>NOTICE OF BINDING ARBITRATION AGREEMENT AND CLASS ACTION WAIVER. PLEASE NOTE THAT ANY DISPUTE OR CLAIM RELATED TO THESE TERMS OR ARISING OUT OF YOUR ACCESS OR USE OF THE SERVICE MUST BE RESOLVED BY ARBITRATION ON AN INDIVIDUAL BASIS AND MAY NOT BE ARBITRATED OR OTHERWISE PURSUED AS A CLASS ACTION. PLEASE SEE THE "DISPUTE RESOLUTION; CLASS ACTION WAIVER" SECTION BELOW.</p>
            <h5>Eligibility</h5>
            <p>In order to access or use the Service, you must be a resident of the United States and at least 18 years of age or the age of majority in your state of residence.</p>
            <h5>GrowBevy Services</h5>
            <p>GrowBevy facilitates the payment of bills through the Service. No funds flow through accounts owned by GrowBevy during the processing of such payments; all funds flow through accounts owned by and held at our financial institution service providers.</p>
            <h5>Account</h5>
            <p>In order to access or use the Service, you must provide your email address, at least one valid payment method (e.g., demand deposit account for ACH or Mastercard-, Visa-, American Express-, or Discover-branded debit or credit card), any information required to validate your payment method, and any other information required for the payee to accept your payment. You represent and warrant that you own the email address you provide and all information entered or collected in the course of accessing or using the Service.</p>
            <p>You may register for an account with us (an "Account") by creating a password and providing certain information, including, without limitation, your name, email address, text-enabled mobile phone number, street address, zip code, date of birth, social security number, and government-issued identification number (e.g., driver license or passport number). All Account information you provide must at all times be true, accurate, and complete, and you must update your Account information as necessary. We will have no liability for relying on any Account information you provide. We have the right, in our sole discretion and for any or no reason, to decline your registration.</p>
            <p>When you register for an Account and from time to time thereafter, your Account may be subject to verification, including validation of any information you provide to us against third-party databases. You authorize us to make inquiries, directly or through third parties, to validate such information, though we are under no obligation to do so.</p>
            <p>You are solely responsible for safeguarding and maintaining the confidentiality of your Account password and agree not to share it with any person who is not authorized to use your Account. You agree that we may assume that any person using the Service with your Account password either is you or is authorized to act for you. You agree to notify us immediately if you suspect or become aware of any unauthorized use of your Account or any unauthorized access to the password for your Account. We will have no liability arising from your failure to comply with these obligations.</p>
            <h5>Payment Methods</h5>
            <p>Acceptable payment methods include debit or credit cards issued on the Mastercard, Visa, American Express, or Discover network.</p>
            <h5>Payment Instructions</h5>
            <p>In order to submit bill payment instructions, you must complete the following steps:</p>
            <ol>
                <li>Take and upload a photo of a paper bill, import an image of a paperless bill that includes the payee and amount due, or otherwise provide the required information for the payee.</li>
                <li>Input the amount to pay and indicate the payment method to be used.</li>
                <li>Provide all other required information.</li>
            </ol>
            <p>You authorize us, upon receipt of payment instructions from you, to cause the applicable payment method to be debited or charged for the applicable amount and the payment to be sent to the payee in accordance with the payment instructions. We have the right, in our sole discretion and for any or no reason, to reject any payment instructions.</p>
            <p>You must promptly notify us upon learning of or suspecting any errors or inaccuracies in any payment instructions and cooperate with us to correct any payments made that are misdirected, unauthorized, erroneous, or duplicative. You acknowledge and agree that GrowBevy does not guarantee or have control over or liability for: (1) the products, services, or other consideration that you receive from your payees; (2) any inaccurate or incomplete payment instructions received from you; (3) payment of any payment instruction received from you being made within the timeframe agreed to between you and your payee; (4) insufficient funds in or the expiration or closure of the payment method indicated in your payment instructions; (5) any refusal by your payee to accept a payment; or (6) any error, failure, or malfunction of your computer or other equipment used for the Service or any security breach, compromise, intrusion, or misuse of your computer or other equipment used for the Service.</p>
            <p>You may make a stop payment request for any payment instructions by contacting us at help@growbevy.com. We will use reasonable efforts to honor stop payment requests but will have no liability if any stop payment request is not effected.</p>
            <h5>Fees</h5>
            <p>Certain fees may be charged in order to process certain payment instructions. We will disclose all applicable fees for your approval prior to processing any payment instruction.</p>
            <h5>Termination</h5>
            <p>We may terminate or suspend the Service or your use of the Service or your Account immediately at any time for any reason, including, without limitation, if: (1) you breach these Terms; (2) we are required to do so by law; or (3) we elect to discontinue the Service. Additionally, we may terminate or suspend the Service or your use of the Service or your Account at any time without cause. You may terminate your use of the Service and your Account at any time.</p>
            <h5>Notices</h5>
            <p>We may provide notices to you by: (a) emailing them to your Account's email address or (b) mailing them via courier or regular mail to your Account's street address. You may provide notices to us via email at help@growbevy.com. Notices sent by email will be deemed received upon receipt unless a party receives notice that the email was not delivered. Notices sent by courier will be deemed received upon actual receipt. Notices sent by regular mail will be deemed received three (3) business days after deposit in the mail with first-class postage prepaid.</p>
            <h5>Disclaimers</h5>
            <p>TO THE FULLEST EXTENT PERMITTED BY LAW, GROWBEVY DISCLAIMS ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS OF ANY KIND (EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY, FITNESS OR SUITABILITY FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS) AS TO THE SERVICE. GROWBEVY DOES NOT MAKE ANY WARRANTY THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY, OR ERROR-FREE.</p>
            <h5>Limitation of Liability</h5>
            <p>GROWBEVY SHALL NOT BE LIABLE TO YOU FOR ANY INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES OF ANY KIND OR NATURE, OR FOR ANY DAMAGES FOR LOSS OF PROFITS, LOSS OR INTERRUPTION OF BUSINESS, LOSS OF USE, LOSS OF DATA, LOSS OF OTHER INTANGIBLES, LOSS OF SECURITY OF INFORMATION PROVIDED IN CONNECTION WITH THE USE OF THE SERVICE, OR UNAUTHORIZED INTERCEPTION OF ANY SUCH INFORMATION BY THIRD PARTIES, WHETHER SUCH LIABILITY IS ASSERTED ON THE BASIS OF CONTRACT, TORT (INCLUDING NEGLIGENCE OR STRICT LIABILITY), OR OTHERWISE. GROWBEVY'S AGGREGATE LIABILITY FOR DAMAGES IN CONNECTION WITH THESE TERMS AND THE SERVICE, REGARDLESS OF THE FORM OF ACTION GIVING RISE TO SUCH LIABILITY (UNDER ANY THEORY, WHETHER IN CONTRACT, TORT, STATUTORY OR OTHERWISE), SHALL NOT EXCEED UNDER ANY CIRCUMSTANCES THE AGGREGATE AMOUNT OF FEES PAID BY YOU TO US UNDER THESE TERMS DURING THE PRIOR TWELVE (12) MONTHS.</p>
            <h5>Governing Law</h5>
            <p>Any dispute, claim, or cause of action arising from the Service or these Terms ("Dispute") shall be governed by and construed in accordance with the laws of the State of California, without regard to its conflict of laws provisions.</p>
            <h5>Dispute Resolution; Class Action Waiver.</h5>
            <p>YOU SHOULD REVIEW THIS PROVISION CAREFULLY. TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOU ARE GIVING UP YOUR RIGHT TO GO TO COURT TO ASSERT OR DEFEND YOUR RIGHTS, EXCEPT FOR MATTERS THAT YOU FILE IN SMALL CLAIMS COURT IN THE STATE OR MUNICIPALITY OF YOUR RESIDENCE WITHIN THE JURISDICTIONAL LIMITS OF THE SMALL CLAIMS COURT AND AS LONG AS SUCH MATTER IS ONLY PENDING IN THAT COURT. YOUR RIGHTS WILL BE DETERMINED BY A NEUTRAL ARBITRATOR AND NOT A JUDGE OR JURY. YOU ARE ENTITLED TO A FAIR HEARING, BUT THE ARBITRATION PROCEDURES MAY BE SIMPLER AND MORE LIMITED THAN RULES APPLICABLE IN COURT. AN ARBITRATOR'S DECISION IS AS ENFORCEABLE AS ANY COURT ORDER AND IS SUBJECT TO VERY LIMITED REVIEW BY A COURT. YOU EXPRESSLY AGREE TO THIS PROVISION AS A CONDITION OF ACCESSING OR USING THE SERVICE.</p>
            <p>If you have a Dispute, you must first notify us at help@growbevy.com and attempt to resolve it with us informally. If we are unable to resolve any Dispute you bring to our attention within sixty (60) days of receipt of your notice or we raise any Dispute, you and we agree that, except where prohibited by law, all Disputes shall be resolved individually and exclusively by final and binding arbitration administered by the American Arbitration Association ("AAA") and conducted before a single arbitrator pursuant to the Consumer Arbitration Rules of the American Arbitration Association (the "AAA Rules"). For more information on the AAA, the AAA Rules, or the process for filing an arbitration claim, you may call the AAA at (800) 778-7879 or visit the AAA website at http://www.adr.org.</p>
            <p>You and we agree to the following with respect to the arbitration of any Dispute: (1) ANY CLAIM MUST BE BROUGHT IN YOUR INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING; (2) THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON'S CLAIMS, MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING, AND MAY NOT AWARD CLASS-WIDE RELIEF; (3) the arbitration will be held at a location in your hometown area unless you and we both agree to another location or telephonic arbitration; (4) you will pay any filing fee or other costs of arbitration only as required under the AAA Rules or as otherwise specified by the arbitrator; (5) we reserve the right, in our sole discretion, to assume responsibility for any or all of the costs of the arbitration; (6) the arbitrator will honor claims of privilege and privacy recognized at law; (7) the arbitration will be confidential, and neither you nor we may disclose the existence, content, or results of any arbitration, except as may be required by applicable law or for purposes of enforcement of the arbitration award; (8) the arbitrator may award any individual relief or individual remedies that are expressly permitted by applicable law; and (9) each party will pay its own attorneys' fees and expenses, unless there is a statutory provision that requires the prevailing party to be paid its fees and litigation expenses and the arbitrator awards such attorneys' fees and expenses to the prevailing party, and, in such instance, the fees and costs awarded by the arbitrator will be determined in accordance with the applicable law. ANY RIGHT TO A TRIAL BY JURY, WHETHER ON AN INDIVIDUAL OR A CLASS BASIS, IS HEREBY WAIVED.</p>
            <p>This Section will survive the termination of the Service or these Terms. With the exception of the provisions prohibiting arbitration on a class or collective basis, if any part of this arbitration provision is deemed to be invalid, unenforceable, or illegal, or otherwise conflicts with the AAA Rules, then the balance of this provision will remain in effect and will be construed in accordance with its terms as if the invalid, unenforceable, illegal, or conflicting part was not contained herein. If for any reason a claim proceeds in court rather than in arbitration, the Dispute will be exclusively brought in federal court if it has jurisdiction or, if it does not, in a state court located in the federal judicial district of your residence.</p>
            <h5>Entire Agreement</h5>
            <p>These Terms, including GrowBevy's privacy policy, constitute the entire agreement and understanding between you and us relating to the Service and cancel and supersede any prior or contemporaneous agreements and other communications between you and us, whether written or oral, to the extent they relate in any way to the Service. If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions shall remain in effect.</p>
            <h5>Amendments</h5>
            <p>GrowBevy may, in its sole discretion, amend these Terms at any time by posting a revised version on the Service. The effective date of any amendments to these Terms will be the date the revised version of these Terms is posted on the Service. By accessing or using the Service after the effective date of any amendments to these Terms, you agree to be bound by the revised version of these Terms.</p>
            <h5>No Waiver</h5>
            <p>The failure or delay of either party to exercise or enforce any right or claim does not constitute a waiver of such right or claim and will in no way affect that party's right to later enforce or exercise it.</p>
            <h5>Force Majeure</h5>
            <p>We will not be responsible for any failure or delay in performance of any obligation hereunder due to labor disturbances, accidents, fires, floods, telecommunications or Internet failures, strikes, wars, riots, rebellions, blockades, epidemic, pandemic, disease outbreak (including the COVID-19 virus), acts of government, governmental requirements and regulations, restrictions imposed by law, or any other similar conditions beyond GrowBevy's reasonable control.</p>
        </div>
    );
}