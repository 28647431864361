import ReactGA from 'react-ga';
import $ from "jquery";

export function initialize(trackingId) {
    if (process.env.NODE_ENV === "production") ReactGA.initialize(trackingId);
    else console.log("ReactGA.initialize", trackingId);
}

export function pageview(pageUrl) {
    if (process.env.NODE_ENV === "production") ReactGA.pageview(pageUrl);
    else console.log("ReactGA.pageview", pageUrl);
}

export function event(payload) {
    if (process.env.NODE_ENV === "production") ReactGA.event(payload);
    else console.log("ReactGA.event", payload);
}

export function hideFormButton() {
    $('#btnContactUs').removeClass("d-block").addClass("d-none");
}