import React, { createContext, useReducer } from "react";
import { reducer } from "./reducer";
export const AppContext = createContext();

const initialState = {
    isAuthenticated: false,
    userHasAuthenticated: false,
    isSupportMember: false,
    isAdminMember: false
};

const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <AppContext.Provider value={{ state, dispatch }}>
        {children}
        </AppContext.Provider>
    );
};

export default AppProvider;

// import { useContext, createContext } from "react";
// export const AppContext = createContext(null);
// export function useAppContext() {
//     return useContext(AppContext);
// }