import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import ComingSoon from "./containers/ComingSoon";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import TermsService from "./containers/TermsService";
import NotFound from "./containers/NotFound";
import ContactUs from "./containers/ContactUs";
import About from "./containers/About";

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route exact path="/landing/:key">
                <Home />
            </Route>
            <Route exact path="/search">
                <Home />
            </Route>
            <Route exact path="/coming-soon">
                <ComingSoon />
            </Route>
            <Route exact path="/coming-soon/:feature">
                <ComingSoon />
            </Route>
            <Route exact path="/contact-us">
                <ContactUs />
            </Route>
            <Route exact path="/privacy-policy">
                <PrivacyPolicy />
            </Route>
            <Route exact path="/terms-service">
                <TermsService />
            </Route>
            <Route exact path="/about">
                <About />
            </Route>
            {/* Finally, catch all unmatched routes */}
            <Route>
                <NotFound />
            </Route>
        </Switch>
    );
}