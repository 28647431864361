import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import "./About.css";
import * as TrackingLib from "../libs/tracking";

export default function About() {
    useEffect(() => {
        TrackingLib.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="About pt-3">
            <Row>
                <Col md={{ span: 8, offset: 2 }} className="text-justify">
                    <h1 className="display-5 text-center">About GrowBevy</h1>
<h5>We want to make passive income accessible for the next generation.</h5>

<p>GrowBevy is a consumer financial gamified application that allows anyone, regardless of their background, to access the opportunities within Decentralized Finance.</p>

<p>DeFi, or Decentralized Finance, has the power to advance everyones financial health. However, the existing onramps to get introduced to the field are clunky, complex, and require a wealth of technical knowledge to use properly.</p>

<p>Financial opportunities should be easy and most importantly accessible. We're creating a more inclusive approach to the future of finance, with an app that's simple to use and most importantly fun.</p>
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 8, offset: 2 }} className="text-justify">
<h5 className="text-center">GrowBevy is Secure</h5>
<p>Security is very important to us. Our app is bank-level secure, level II PCI compliant, HIPAA compliant, 
and all data is transferred over an SSL connection (Secure Sockets Layer/HTTPS).</p>
                </Col>
            </Row>
        </div>
    );
}