import React, { useEffect, useContext, useState } from "react";
import { Navbar, Nav, Form, FormControl, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "../libs/context";
import * as TrackingLib from "../libs/tracking";

export default function NavBar({ ...props }) {
    const history = useHistory();
    const { state, dispatch } = useContext(AppContext);
    const [criteria, setCriteria] = useState("");
    const [expanded, setExpanded] = useState(false);
    async function handleLogout() {
        TrackingLib.hideFormButton();
        setExpanded(false);
        dispatch({
            type: "LOGOUT"
        });
        history.push("/");
    }
    useEffect(() => {
        if (props.isAuthenticated === true && props.authenticatedUser) dispatch({type: "LOGIN", payload: props.authenticatedUser});
        return () => {
            setCriteria("");
        };
    }, [props.isAuthenticated, props.authenticatedUser, dispatch]);

    function onClickSearch() {
        if (criteria && criteria.length > 3) {
            window.top.location.href = `/search?criteria=${encodeURIComponent(criteria)}`;
        } // if
    }
    let badgeNotificationsCount = props.isAuthenticated === true && props.authenticatedUser && props.authenticatedUser.badges && props.authenticatedUser.badges.userNotificationsUnread;
    let notificationsTotal = props.isAuthenticated === true && props.authenticatedUser && props.authenticatedUser.badges && props.authenticatedUser.badges.userNotificationsTotal;
    let homeLink = "/";
    return (
        <Navbar bg="light" expand="lg" expanded={expanded}>
            <LinkContainer to={homeLink} onClick={() => setExpanded(false)}>
                <Navbar.Brand>
                    <img
                        alt=""
                        src={`${process.env.PUBLIC_URL}/images/main-logo.png`}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    /> GrowBevy
                </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
            <Navbar.Collapse id="basic-navbar-nav">
                <Navbar.Collapse className="justify-content-end">
                    <Nav>
                    <LinkContainer to="/about" onClick={() => setExpanded(false)}>
                        <Nav.Link>About</Nav.Link>
                    </LinkContainer>
                    </Nav>
                    <Nav>
                    <LinkContainer to="/contact-us" onClick={() => setExpanded(false)}>
                        <Nav.Link>Contact Us</Nav.Link>
                    </LinkContainer>
                    </Nav>
                </Navbar.Collapse>
            </Navbar.Collapse>
        </Navbar>
    );
}