import React, { useState, useEffect } from "react";
import Routes from "./Routes";
import NavBar from "./components/NavBar";
import { Row, Col, Image, Button } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import AppProvider from "./libs/context";
import { onError } from "./libs/error";
import * as TrackingLib from "./libs/tracking";
import './App.css';
TrackingLib.initialize('UA-144119903-5');

function App() {
	const [authenticatedUser, setAuthenticatedUser] = useState(null);
	const [isAuthenticating, setIsAuthenticating] = useState(true);
	const [isAuthenticated, userHasAuthenticated] = useState(false);
	return (
		<AppProvider>
			<NavBar isAuthenticated={isAuthenticated} authenticatedUser={authenticatedUser} />
			<div className="App container-fluid">
				<Routes />
				<Button id="btnContactUs" className="d-none" style={{position: "fixed", bottom: 25, right: 25}} variant="primary">Need Help?</Button>
				<hr />
				<footer className="my-5 pt-5 text-muted text-center text-small">
					<p className="mb-1">&copy; 2020. GrowBevy. All Rights Reserved.</p>
					<ul className="list-inline">
						<li className="list-inline-item"><a href="/privacy-policy">Privacy Policy</a></li>
						<li className="list-inline-item"><a href="/terms-service">Terms of Service</a></li>
					</ul>
				</footer>
			</div>
		</AppProvider>
	);
}

export default App;
