import React from "react";
// import { Spinner } from "react-bootstrap";

export default function PageLoader({ message = "Loading..." }) {
    return (
        <div className="text-center" style={styles.backdrop}>
            <div className="text-center" style={styles.container}>
                {/* <Spinner animation="border" role="status">
                    <span className="sr-only">{message}</span>
                </Spinner> */}
                <div className="d-flex align-items-center" style={styles.innerContainer}>
                    <h5 style={{marginBottom: 0}}>{message}</h5>&nbsp;
                    <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                </div>
            </div>
        </div>
    );
}

const styles = {
    backdrop: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: 10000
    },
    container: {position: "fixed", zIndex: 1000, top: "50%", left: "50%", transform: "translate(-50%, -50%)"},
    innerContainer: {
        padding: 50,
        paddingLeft: 100,
        paddingRight: 100,
        backgroundColor: "#EEEEEE"
    }
};