import React, { useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import * as TrackingLib from "../libs/tracking";
// import "./PrivacyPolicy.css";

export default function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
        TrackingLib.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className="PrivacyPolicy pt-3 text-justify">
            <h3 className="text-center">GrowBevy - Privacy Policy</h3>
            <p className="text-center">Effective Date: June 17th, 2020</p>
            <p>GrowBevy respects your privacy and is committed to protecting it through compliance with this privacy policy ("Privacy Policy"). The Privacy Policy describes the information we collect about you, how it is used, and with whom it is shared, and it explains the rights and choices you have to control how we handle your Personal Information.</p>
            <p>This Privacy Policy describes:</p>
            <ul>
                <li>Personal Information We Collect</li>
                <li>How We Use Your Personal Information</li>
                <li>How We Share Your Personal Information</li>
                <li>Cookies, Web Beacons, and Similar Technologies</li>
                <li>Your Choices</li>
                <li>How We Protect Your Personal Information</li>
                <li>Your California Privacy Rights</li>
                <li>Information for Nevada Residents</li>
                <li>Children's Privacy</li>
                <li>How to Contact Us</li>
                <li>Updates to the Privacy Policy</li>
            </ul>
            <p>In this Privacy Policy, "GrowBevy," "we," or "our" means MergeCreative, LLC. d/b/a GrowBevy. "Website(s)" means GrowBevy-operated websites and mobile applications, including growbevy.com, and bhelp.com and the GrowBevy iOS and Android applications.</p>
            <p>This Privacy Policy applies to information GrowBevy collects about you through our Websites, as well as any other website or app that is owned or operated by a U.S. affiliate, or any other features, content, mobile applications, or other communications or services we offer from time to time in connection with any of the foregoing ("Services"). This Privacy Policy does not apply outside of the United States.</p>
            <p>Unless stated otherwise, our Privacy Policy applies to all information GrowBevy collects that is or reasonably could be associated with you, including your online account information ("Personal Information"). Please read this Privacy Policy before using or accessing the Services. By using our Services, you communicate your understanding and acceptance of this Privacy Policy.</p>
            <h4>Personal Information We Collect</h4>
            <h5>Information You Provide to Us</h5>
            <p>We receive and store any information you enter on our Websites or provide or use in connection with our Services. You can choose not to provide certain information, but this may limit your ability to take advantage of some features, promotions, or other Services.</p>
            <ul>
                <li>Contact and Registration Information such as your name, contact information, email address, username and password.</li>
                <li>Identification Data such as your driver’s license number, other government ID number, or employer identification number</li>
                <li>Linked Financial Account Data such as your financial account number, payment card number, or username and password to an online account.</li>
                <li>Bills including any information contained in photographs of bills that you upload to the Services, including health and wellness data, information on purchases, and account balances.</li>
                <li>Transaction Information such as information related to your payment transactions.</li>
                <li>Questionnaires, surveys, or contests including any information you provide when participating in surveys or contests that GrowBevy may run from time to time.</li>
                <li>Interactions with GrowBevy Associates including any communications and requests to GrowBevy associates, such as for customer assistance.</li>
                <li>Content you post to our Websites such as comments that you post to our Websites, forums, and discussion areas.</li>
                <li>Other information you provide such as profile data that you upload to your account.</li>
            </ul>
            <h5>Information We Collect Automatically</h5>
            <p>We and our service providers may use technologies such as cookies, beacons, tags, and scripts, to analyze trends, administer our Websites and improve functionality, and to gather general demographic information about our user base as a whole. Such information may include:</p>
            <ul>
                <li>Device Information such as your IP address, browser information, and device type.</li>
                <li>Usage information such as browsing behavior on our Websites, URL information, click stream information, telemetry on your interactions with our Services, and date/time stamps.</li>
            </ul>
            <h4>How We Use Your Personal Information</h4>
            <p>We use your Personal Information to provide, support, personalize, and develop our Services. In particular, we may use your Personal Information:</p>
            <ul>
                <li>To Provide Our Services, such as to provide products and services that you request;register and manage your account; and provide and manage the Services.</li>
                <li>To Market and Advertise to You, such as to send you updates, newsletters, promotions, and surveys; personalize notifications and service offerings to you; manage your participation in events and sweepstakes; communicate with you about our products and services; publish customer testimonials or photos; analyze our marketing strategies and trends regarding your use of our website or social media channels; improve the effectiveness of our marketing campaigns; and/or send follow-up communications thanking you for your business or inquiring as to your satisfaction.</li>
                <li>To Improve Our Services, such as engage in analysis, research, and reports regarding use of the Services; track the efficacy of our Websites and/or improve the Website experience; and for troubleshooting and maintenance purposes.</li>
                <li>To Secure our Services and Users, such as protect against error, fraud, unauthorized transactions, claims and other liabilities; manage exposure to risk from unauthorized users; enforce the terms of use on our site; and/or comply with applicable legal mandates, our policies, or industry standards.</li>
                <li>To Provide Customer Service, such as provide customer service when you need help and/or to improve customer experiences; provide status updates on requested transactions; or inform you about product releases or updates.</li>
                <li>Or for any other purpose not listed here, with your consent.</li>
            </ul>
            <p>In addition to the above purposes, we may collect and summarize customer and visitor information in aggregated, de-identified form for any purposes, including statistical or research.</p>
            <h4>How We Share Your Personal Information</h4>
            <p>As explained in greater detail below, we may disclose certain categories of Personal Information for the purposes and with the types of entities set forth in this section for business purposes (as defined by applicable law) or as required by applicable law:</p>
            <h5>At Your Direction</h5>
            <p>We may share your Personal Information with third parties when you direct us to do so, such as to pay a third party based on a bill you upload to the Services.</p>
            <h5>Service Providers</h5>
            <p>We may share your Personal Information with other entities who perform services on our behalf such as payment processing, email distribution, sending marketing communications, servicing products, maintenance of your account, conducting research surveys, verifying and validating information that you have provided to us, and providing customer support services. Such entities are contractually obligated to maintain the confidentiality of your personal information.</p>
            <p>GrowBevy uses Stripe, Inc. ("Stripe") to gather your data from financial institutions. By using the Services, you grant GrowBevy and Stripe the right, power, and authority to act on your behalf to access and transmit your personal and financial information from the relevant financial institution. You agree to your personal and financial information being transferred, stored, and processed by Stripe in accordance with the Stripe Privacy Policy.</p>
            <p>GrowBevy also uses Plaid Inc. ("Plaid") to gather your data from your financial institutions if you opt to pay with your bank account. By using these Services, you grant GrowBevy and Plaid the right, power, and authority to act on your behalf to access and transmit your personal and financial information from the relevant financial institution. You agree to your personal and financial information being transferred, stored, and processed by Plaid in accordance with the Plaid Privacy Policy.</p>
            <h5>Sale or Transfer of Business or Assets</h5>
            <p>Your Personal Information may be transferred or disclosed to a purchaser or prospective purchaser in the event of a sale, assignment, or other transfer of all or a portion of our business or assets.</p>
            <h5>Legal Disclosure</h5>
            <p>We may disclose information about you when required by law, subpoena, or other legal process or if we have a good faith belief that disclosure is reasonably necessary to (1) investigate, prevent, or take action regarding suspected or actual illegal activities or to assist government enforcement agencies; (2) enforce our agreements with you, (3) investigate and defend ourselves against any third-party claims or allegations; (4) protect your or our rights or safety, or that of others, or to protect the security or integrity of our Service, including but not limited to by exchanging information with other companies and organizations for anti-fraud/anti-bot and credit risk reduction purposes.</p>
            <h5>Links to Third Parties</h5>
            <p>Links to third-party websites are provided as a convenience to you. Once you leave our website, we neither control nor have responsibility for third-party sites, their content, or their privacy practices. We encourage you to read the terms and conditions and privacy policy of each third-party service that you access or utilize.</p>
            <h4>Cookies, Web Beacons, and Similar Technologies</h4>
            <p>We may use cookies and similar technologies (e.g. web beacons, pixels, ad tags and device identifiers) to recognize you and/or your device(s) on, off and across different Services and devices. For detailed information on the cookies we use, the purposes for which we use them, and how to disable them, see below.</p>
            <h5>Cookies</h5>
            <p>We automatically collect information through the use of cookies. Cookies are small text files stored by your Web browser. Cookies allow us to identify and authenticate visitors, track aggregate behavior, and enable site features. For instance, we or authorized third parties may use cookies to help improve the site experience or prevent fraud.</p>
            <p>We use session ID cookies and persistent cookies. A session ID cookie expires when you close your browser. We use session cookies to maintain your shopping state as you browse through the site. We also contract with analytics services and third-party advertising companies to collect similar information for specific purposes. The use of cookies by our partners is not covered by this Privacy Policy.</p>
            <p>Most browsers will tell you how to manage your cookies, often through the "help" area on your browser's toolbar. Because cookies allow you to have the benefit of our personalized features, we recommend that you leave them turned on. Disabling cookies for our Websites may impact their usability.</p>
            <h5>Targeted Advertising</h5>
            <p>Third-party advertising companies serve ads on our behalf across the Internet. They may collect information about your browsing activities through cookies on other sites, and then display targeted ads on various sites that you visit. If you wish to opt-out of interest-based ads, then you may do so here. Please note this does not opt you out of being receiving ads, only targeted ones. To opt out of third-party advertising or analytics, please see section "How to Contact Us" below.</p>
            <h5>Social Media</h5>
            <p>Our Sites include social media features, such as the Facebook "Like" button and widgets, the "share this" widget, or interactive mini-programs that run on our site. These features may collect your IP address, which page you are visiting on our Site, and may set a cookie to enable the feature to function properly. Social media features and widgets are either hosted by a third party or hosted directly on our Sites. Your interactions with these features are governed by the privacy statement of the company providing it. We may post GrowBevy information on social media sites. Your comments and other reactions will be visible if you respond.</p>
            <h4>Your Choices</h4>
            <h5>Accessing, Changing, and Modifying Your Account Information and Settings</h5>
            <p>We provide a number of choices about the collection, use, and sharing of your Personal Information:</p>
            <ul>
                <li>If you have registered for an account, you can access, review and manage many changes yourself via the customer dashboard in our app. Within the dashboard, you can do things like update your profile information and change your communication preferences.</li>
                <li>If you have not registered for an account, or the information you are seeking is not available within the customer dashboard, you can submit a request using the contact details in the "How to Contact Us" section of this Privacy Policy.</li>
                <li>You may opt out of receiving marketing emails from us at any time by clicking on the unsubscribe link in our emails. You will continue to receive service-related emails (e.g., order status).</li>
                <li>We may provide you the opportunity to participate in contests, sweepstakes, surveys and/or other promotions on our Websites. If you participate, we will request Personal Information from you.</li>
            </ul>
            <h5>Analytics</h5>
            <p>We use analytics services, such as Google Analytics, to analyze information regarding visits to our websites and mobile applications. Such services use cookies, web beacons, recorded interactions and other tracking mechanisms to collect this information.You may use your browser to control how your information is used. To prevent Google Analytics from using your information for analytics, you may install the Google Analytics Opt-out Browser Add-on by clicking here.</p>
            <h5>Do Not Track</h5>
            <p>Do Not Track ("DNT") is a privacy preference that users can set in certain web browsers. We are committed to providing you with meaningful choices about the information collected on our Services for third party purposes, as listed above. However, we do not currently recognize or respond to browser-initiated DNT signals.</p>
            <h5>Data Retention and Account Closure</h5>
            <p>We may keep some of your Personal Information after you close your account to comply with our legal obligations (including law enforcement requests), meet regulatory requirements, resolve disputes, maintain security, prevent fraud and abuse, enforce our Terms and Conditions, or fulfill your request to "unsubscribe" from further messages from us. We may retain de-identified information after your account has been closed.</p>
            <h4>How We Protect Your Personal Information</h4>
            <h5>Security</h5>
            <p>We implement a variety of security safeguards designed to protect your Personal Information. However, no method or transmission over the internet or electronic system is completely secure, so there is no guarantee that such data may not be accessed, disclosed, altered, or destroyed in connection with a breach of our physical, technical, organizational, or managerial safeguards.</p>
            <h5>Choosing a Password</h5>
            <p>When you register as a user and create an online account on our Website, you will be prompted to select a personal password. To maximize your level of protection, you should choose a strong and unique password. You are solely responsible for maintaining the secrecy of your password and any account information.</p>
            <h4>Your California Privacy Rights</h4>
            <p>The California Consumer Privacy Act of 2018 ("CCPA") provides certain rights to residents of California. This section of the Privacy Notice applies if you are a natural person who is a resident of California ("California Consumer") and uses our Services. This notice supplements the information in the Privacy Policy with respect to California Consumers. Certain terms used below have the meanings given to them in the CCPA.</p>
            <p>We collect and disclose the following categories of personal information as indicated with a check mark below:</p>
            <table>
                <thead>
                    <tr>
                        <td></td>
                        <td>Collected</td>
                        <td>Disclosed</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> A. Identifiers such as a real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address and account name. </td>
                        <td><span>✔</span></td>
                        <td><span>✔</span></td>
                    </tr>
                    <tr>
                        <td> B. Personal information as defined in the California customer records law, such as name, contact information, education, employment, employment history and financial information. </td>
                        <td><span>✔</span></td>
                        <td><span>✔</span></td>
                    </tr>
                    <tr>
                        <td> C. Characteristics of protected classifications under California or federal law, such as gender and date of birth. </td>
                        <td><span>✔</span></td>
                        <td><span role="img" aria-label="Multiply">✖️</span></td>
                    </tr>
                    <tr>
                        <td> D. Commercial information, such as transaction information, purchase history, financial details and payment information. </td>
                        <td><span>✔</span></td>
                        <td><span>✔</span></td>
                    </tr>
                    <tr>
                        <td>E. Biometric information, such as fingerprints and voiceprints.</td>
                        <td><span role="img" aria-label="Multiply">✖️</span></td>
                        <td><span role="img" aria-label="Multiply">✖️</span></td>
                    </tr>
                    <tr>
                        <td> F. Internet or other electronic network activity information, such as browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems and advertisements. </td>
                        <td><span>✔</span></td>
                        <td><span>✔</span></td>
                    </tr>
                    <tr>
                        <td>G. Geolocation data, such as device location.</td>
                        <td><span role="img" aria-label="Multiply">✖️</span></td>
                        <td><span role="img" aria-label="Multiply">✖️</span></td>
                    </tr>
                    <tr>
                        <td> H. Audio, electronic, visual and similar information, such as images and audio, video or call recordings created in connection with our business activities. </td>
                        <td><span>✔</span></td>
                        <td><span role="img" aria-label="Multiply">✖️</span></td>
                    </tr>
                    <tr>
                        <td> I. Professional or employment-related information, such as job title as well as work history and experience. </td>
                        <td><span>✔</span></td>
                        <td><span role="img" aria-label="Multiply">✖️</span></td>
                    </tr>
                    <tr>
                        <td> J. Education information subject to the federal Family Educational Rights and Privacy Act, such as student records. </td>
                        <td><span role="img" aria-label="Multiply">✖️</span></td>
                        <td><span role="img" aria-label="Multiply">✖️</span></td>
                    </tr>
                    <tr>
                        <td> K. Inferences drawn from any of the personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics. </td>
                        <td><span>✔</span></td>
                        <td><span role="img" aria-label="Multiply">✖️</span></td>
                    </tr>
                </tbody>
            </table>
            <p>We collect this personal information from you and from other categories of sources such as: public and publicly available sources; our vendors, data suppliers, and service providers; partners with which we may offer co-branded services or engage in joint event or marketing activities; social networks; and the organization with which you are employed or affiliated.</p>
            <p>We share this personal information with: our service providers; the administrators authorized by your organization (if you access our services through a subscription administrated by your organization); and other parties where required by law or to protect our rights.</p>
            <p>We may use this personal information to: operate, manage, and maintain our business, to provide our products and services, to communicate with you, for our vendor management purposes, and to accomplish our business purposes and objectives, including, for example, using personal information to: develop, improve, repair, and maintain our products and services; process or fulfill a request or other transactions submitted to us; personalize, advertise, and market our products and services; conduct research, analytics, and data analysis; maintain our facilities and infrastructure; undertake quality and safety assurance measures; conduct risk and security control and monitoring; detect and prevent fraud; perform identity verification; perform accounting, audit, and other internal functions; comply with law, legal process, and internal policies; maintain records; exercise and defend legal claims; and fulfill legal obligations.</p>
            <p>GrowBevy does not sell personal information to third parties within the scope of the application of the CCPA.</p>
            <h5>California Privacy Rights</h5>
            <p>California residents have the right to request:</p>
            <ul>
                <li>The categories of Personal Information we collect, along with the categories of sources from which we collect such information, the categories of third parties to whom we disclose it, and the business purposes for such disclosure;</li>
                <li>The specific pieces of information we have collected about you;</li>
                <li>Deletion of certain Personal Information we have collected from you; and</li>
                <li>To opt-out of the sale of your Personal Information, to the extent that such sales occur.</li>
            </ul>
            <p>We will not deny, charge different prices for, or provide a different level of quality of services if you choose to exercise these rights. Note that each of these rights is subject to certain restrictions and exceptions under California law; for example, we may need to retain certain information for record keeping or legal purposes.</p>
            <p>California residents may exercise their California privacy rights by submitting your request via one of the methods in the "How to Contact Us" section. You also have the right to designate an agent to exercise these rights on your behalf.</p>
            <p>For security purposes, we will verify your identity – in part by verifying your email account or requesting certain information from you – when you request to exercise your California privacy rights. Once we have verified your identity (and your agent, as applicable), we will respond to your request as appropriate. Consistent with California law, we do not disclose government IDs or financial account numbers</p>
            <h6>Shine the Light Disclosure</h6>
            <p>The California "Shine the Light" law gives residents of California the right under certain circumstances to request information from us regarding the manner in which we share certain categories of Personal Information (as defined in the Shine the Light law) with third parties for their direct marketing purposes. We do not share your Personal Information with third parties for their own direct marketing purposes.</p>
            <h4>Information for Nevada Residents</h4>
            <p>Certain Nevada consumers may opt out of the sale of "personally identifiable information" for monetary consideration to a person for that person to license or sell such information to additional persons. "Personally identifiable information" includes first and last name, address, email address, phone number, Social Security Number, or an identifier that allows a specific person to be contacted either physically or online.</p>
            <p>We do not engage in such activity; however, if you are a Nevada resident who has purchased or leased goods or services from us, you may submit a request to opt out of any potential future sales under Nevada law by contacting us using the methods outlined in the "How to Contact Us" Section.Please note we will take reasonable steps to verify your identity and the authenticity of the request. Once verified, we will maintain your request in the event our practices change.</p>
            <h4>Children's Privacy</h4>
            <p>Our Services are designed for and directed at people 13 years of age or older. We do not knowingly collect information from children under 13. If GrowBevy learns that a child under the age of 13 has submitted personally identifiable information online, it will take reasonable measures to delete such information from its databases and to not use such information (except where necessary to protect the safety of the child or other as required or allowed by law). If you become aware of any Personal Information we have collected from children under 13, please see "How to Contact Us" for details to contact us.</p>
            <h4>How to Contact Us</h4>
            <p>If you have questions about how we collect, store and use Personal Information, please contact us at the following:</p>
            <p>Consumer Rights Web form: <LinkContainer to="/contact-us"><a href="/contact-us">https://growbevy.com/contact-us</a></LinkContainer></p>
            <h4>Updates to the Privacy Policy</h4>
            <p>This Privacy Policy may change from time to time. We will notify you of changes by posting a revised version of the Privacy Policy on this website and updating the "Effective Date" at the top of the Privacy Policy. You should check this website periodically for updates. If we make material changes to the Privacy Policy, then we will provide notice through our Services or by other means, as legally required You acknowledge that your continued use of our Services after we publish or send a notice about our changes to this Privacy Policy means that the collection, use and sharing of your Personal Information is subject to the updated Privacy Policy. If you object to any changes, you may close your account and discontinue use of the applicable Services.</p>
        </div>
    );
}