export default {
    MAX_ATTACHMENT_SIZE: 5000000,
    RECAPTCHA_KEY: "6Lfbz4scAAAAAMjmSWOZtq4FA2NRG4HIprcixLBQ",
    apiGateway: {
        REGION: "us-east-1",
        URL: process.env.NODE_ENV === "production" ? "https://jsvb9wfdze.execute-api.us-east-1.amazonaws.com/prod" : "http://localhost:4000/prod"
    },
    LANDING: {
        default: {
            heading: "Pay Bills On Your Own Terms", // Take a Pic & 
            subheading: "Simply take a photo of your medical or dental bill, specify how much and when you want to pay, and we’ll do the payments for you."
        },
        payWhatYouWant: {
            heading: "Pay Bills How Much and When You Want", // Take a Pic & 
            subheading: "Take a photo of your medical or dental bill, specify how much and when you want to pay, and we’ll do the payments for you."
        },
        neverForgetBills: {
            heading: "Set when you want to pay and forget it", // Take a Pic & 
            subheading: "Take a photo of your medical or dental bill, specify how much and when you want to pay, and we’ll do the payments for you."
        },
        secureYourFinances: {
            heading: "One secure place for your finances", // Take a Pic & 
            subheading: "Take a photo of your medical or dental bill, specify how much and when you want to pay, and we’ll do the payments for you."
        },
        allInOnePortal: {
            heading: "Pay and track bills in one place", // Take a Pic & 
            subheading: "Take a photo of your medical or dental bill, specify how much and when you want to pay, and we’ll do the payments for you."
        },
        fastAndEfficient: {
            heading: "Pay bills fast and efficiently", // Take a Pic & 
            subheading: "Take a photo of your medical or dental bill, specify how much and when you want to pay, and we’ll do the payments for you."
        }
    }
};