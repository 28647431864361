import React, { useRef, useState, useEffect } from "react";
import config from "../config";
import Recaptcha from 'react-invisible-recaptcha';
import { Alert, Accordion, Card } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import PageLoader from "../components/PageLoader";
import * as TrackingLib from "../libs/tracking";
import { API } from "aws-amplify";
// import "./ContactUs.css";

export default function ContactUs() {
    const [name, setName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [message, setMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const refRecaptcha = useRef();

    useEffect(() => {
        window.scrollTo(0, 0);
        TrackingLib.pageview(window.location.pathname + window.location.search);
        return () => {
            setSuccessMessage("");
        };
    }, []);

    async function onResolved() {
        console.log('Recaptcha resolved with response:', refRecaptcha.current.getResponse());
        setIsLoading(true);
        API.get("globals", `/globals?contactUs=${encodeURIComponent(refRecaptcha.current.getResponse())}&name=${encodeURIComponent(name || document.getElementById("inputName").value)}&emailAddress=${encodeURIComponent(emailAddress || document.getElementById("inputEmail1").value)}&message=${encodeURIComponent(message || document.getElementById("inputTextarea").value)}`);
        setSuccessMessage("Thank you for contacting us. We will be in touch as quickly as possible.");
        setName("");
        setEmailAddress("");
        setMessage("");
        setIsLoading(false);
    }

    function onSend() {
        if (emailAddress === "" || name === "" || message === "") {
            alert("All fields are required.");
            refRecaptcha.current.reset();
        } else {
            refRecaptcha.current.execute();
        }
    }

    return (
        <div className="ContactUs mt-3">
            <div className="row">
                <div className="col-md-8 offset-md-2 text-center">
                    <h1 className="display-5">
                        How can we help?<br /><small className="text-muted">Got some question about a plants and their harvest or you simply want to learn more, 
                        let us know and we'll reply as quickly as possible!</small>
                    </h1>
                    <hr />
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="row">
                        <div className="col-md">
                            <h5>
                                In a nutshell...
                            </h5>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">Plant trees and grow your assets.</li>
                                <li className="list-group-item">No Contracts. Cancel anytime.</li>
                                <li className="list-group-item">Redeem for extra cash or compound assets.</li>
                                <li className="list-group-item">SSL/HTTPS Secure, PCI and HIPAA compliant.</li>
                            </ul>
                        </div>
                        <div className="col-md">
                            {successMessage ?
                            <Alert variant="success">{successMessage}</Alert>
                            : null
                            }
                            <form>
                            <div className="form-group">
                                <label htmlFor="inputName">Name</label>
                                <input type="text" className="form-control" id="inputName" onChange={e => setName(e.target.value)} value={name} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputEmail1">Email address</label>
                                <input type="email" className="form-control" id="inputEmail1" aria-describedby="emailHelp" onChange={e => setEmailAddress(e.target.value)} value={emailAddress} />
                                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputTextarea">Message</label>
                                <textarea className="form-control" id="inputTextarea" rows="3" onChange={e => setMessage(e.target.value)} value={message}></textarea>
                            </div>
                            <Recaptcha
                                ref={refRecaptcha}
                                sitekey={config.RECAPTCHA_KEY}
                                onError={(e) => console.error("An error occurred with the ReCaptcha check.", e)}
                                onLoaded={(e) => console.log("ReCaptcha loaded.", e)}
                                onResolved={onResolved} />
                            <button type="button" className="btn btn-primary" onClick={onSend}>Send</button>
                            </form>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <h4>FAQs</h4>
                            <hr />
                            <Accordion>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                        How is such a harvest possible?
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body className="text-left">
                                        GrowBevy accesses DeFi markets that are typically only in reach for technically sophisticated finance and software junkies.

                                        We then use our knowledge to take advantage of high interest decentralized finance platforms. These earnings come back to you as cash when you redeem your harvests.

                                        Unlike your bank, which generates minimal interest on the money in your checking or savings account, harvest can be up to 150x compared to their APY.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="1">
                                        Why isn't everyone doing this?
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body className="text-left">
                                            To be clear, anyone can do this in DeFi - it's a process known as yield "farming". However, existing tools are clunky, complex, or require a wealth of technical knowledge to use properly. We make earning returns in DeFi simple and fun, the way it should be.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="2">
                                        What are the risks?
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body className="text-left">
                                            None. We believe in our platform so much that you can cancel your "plant" anytime and get your money back. We take all the risk, you get all the returns.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="7">
                                        This sounds too good to be true. Am I right?
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="7">
                                        <Card.Body className="text-left">
                                            We believe in what we do and stand by our guarantee. Try us out with a starter plant - "Cherry". Cancel anytime and get your money back.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="3">
                                        Are my harvest redemptions guaranteed?
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="3">
                                        <Card.Body className="text-left">
                                            Yes! Your harvest and plant worth increase at a rate of 1% per month up to 15%. We challenge you to find a bank that will or can give such returns.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="4">
                                        How often can I withdraw my funds?
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="4">
                                        <Card.Body className="text-left">
                                            You can cancel anytime. You can withdraw or redeem your harvests anytime as well. No fees. No catch.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="5">
                                    Is GrowBevy secure?
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="5">
                                        <Card.Body>
                                        GrowBevy takes your security very seriously and uses state of the art technologies to protect it. 
                                        GrowBevy uses bank-level security in all of our processes. Data is securely encrypted and transferred 
                                            over SSL (Secure Socket Layer) connections that are both PCI (level II) and HIPAA compliant.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="6">
                                    Do you sell or give my data to anyone?
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="6">
                                        <Card.Body>
                                            Absolutely not. We have a strict policy of never sharing or selling your data with any third parties. 
                                            We never share your location data or any other type of 
                                            personal data that is not necessary to use our services.<br /><br />
                                            Feel free to read our <LinkContainer to="/privacy-policy"><a href="/privacy-policy">privacy policy</a></LinkContainer>.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ? <PageLoader /> : null}
        </div>
    );
}