export const reducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
        //     localStorage.setItem("user", JSON.stringify(action.payload.user));
        //     localStorage.setItem("token", JSON.stringify(action.payload.token));
            return {
                ...state,
                isAuthenticated: true,
                userHasAuthenticated: true,
                isAdminMember: action.payload.isAdminMember === true,
                isSupportMember: action.payload.isSupportMember === true
                // user: action.payload.user,
                // token: action.payload.token
            };
        case "LOGOUT":
            // localStorage.clear();
            return {
                ...state,
                isAuthenticated: false,
                userHasAuthenticated: false,
                isAdminMember: false,
                isSupportMember: false
                // user: null,
                // token: null,
            };
        // case "ADD_ARTICLE":
        //     return [
        //         ...state,
        //         {
        //             id: Math.random(), // not really unique but it's just an example
        //             title: action.article.title,
        //             body: action.article.body
        //         }
        //     ];
        default:
            return state;
    }
};