import React, { useRef, useState, useEffect, useContext } from "react";
import { Row, Col, Accordion, Button, Image, Alert } from "react-bootstrap";
import { Card, Form, InputGroup, FormControl } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from "react-router-bootstrap";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../libs/context";
import PageLoader from "../components/PageLoader";
import { API } from "aws-amplify";
import "./Home.css";
import config from "../config";
import * as TrackingLib from "../libs/tracking";
import { Chart } from "react-google-charts";
import accounting from "accounting";
import Recaptcha from 'react-invisible-recaptcha';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function Home() {
    const { key = "default" } = useParams();
    const history = useHistory();
    const { state } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    let queryString = useQuery();
    const queryStringSuccess = queryString.get("success") === "true";
    const queryStringMessage = queryString.get("message");
    const queryStringCriteria = queryString.get("criteria");
    const { LANDING } = config;
    let oHeadings = LANDING[key];
    if (!oHeadings) oHeadings = LANDING["default"];

    const [plantType, setPlantType] = useState(1000);
    const [calculatorData, setCalculatorData] = useState([]);
    const [calculatedMonth, setCalculatedMonth] = useState(null);
    const [calculatedReturn, setCalculatedReturn] = useState(null);
    const googleChartInitialized = useRef(false);
    const plantTypes = [
        {name: "Apple", cost: 1000},
        {name: "Lemon", cost: 750},
        {name: "Banana", cost: 500},
        {name: "Kiwi", cost: 200},
        {name: "Raspberry", cost: 100},
        {name: "Cherry", cost: 50}
    ];
    const [emailAddress, setEmailAddress] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const refRecaptcha = useRef();

    useEffect(() => {
        TrackingLib.pageview(window.location.pathname + window.location.search);
        setPlantType(1000);
        setCalculatorData(calculateCalcData(1000));
    }, [queryStringCriteria]);

    const calculateCalcData = (value) => {
        let aCalculatorData = [];
        let aprMultiplier = 0.01;
        let currentApr = 0.01;
        let startingValue = value;
        for (let monthNbr = 1; monthNbr <= 36; monthNbr++) {
            let accruedInterest = (startingValue * currentApr) / 12;
            startingValue += accruedInterest;
            if (currentApr < 0.15) currentApr += aprMultiplier;
            aCalculatorData.push([`${monthNbr} month${monthNbr > 1 ? 's' : ''}`, parseFloat(accounting.formatMoney(startingValue, "", 2, ""))]);
        }
        // console.log("calculateCalcData", aCalculatorData);
        return aCalculatorData;
    };

    const onChangePlantType = (value) => {
        // console.log("onChangePlantType", value);
        setPlantType(value);
        setCalculatorData(calculateCalcData(parseFloat(value)));
        setCalculatedMonth(null);
        setCalculatedReturn(null);
        if (googleChartInitialized.current && window.google &&
            window.google.visualization &&
            window.google.visualization.events) {
            window.google.visualization.events.removeAllListeners(googleChartInitialized.current);
            googleChartInitialized.current = null;
        }
    };

    async function onResolved() {
        console.log('Recaptcha resolved with response:', refRecaptcha.current.getResponse());
        setEmailAddress("");
        API.get("globals", `/globals?contactUs=${encodeURIComponent(refRecaptcha.current.getResponse())}&name=GrowBevy&emailAddress=${encodeURIComponent(emailAddress || document.getElementById("waitlistEmail").value)}&message=${encodeURIComponent("GrowBevy Waitlist")}`);
        setSuccessMessage("Thank you for joining our waitlist. We'll be in touch!");
    }

    function onSend() {
        if (emailAddress === "") {
            alert("Please enter your email address");
            refRecaptcha.current.reset();
        } else {
            refRecaptcha.current.execute();
        }
    }

    function renderLander() {
        return (
            <Container fluid>
                <div className="row lander">
                    <div className="col text-center">
                        <Row className="mb-5">
                            <Col lg className="text-center">
                                <h1 className="d-lg-none">Grow your income.</h1>
                                <h1 className="d-lg-none">Spend your harvest.</h1>
                                <h1 className="headline-lg d-none d-lg-block">Grow your income.</h1>
                                <h1 className="headline-lg mb-5 d-none d-lg-block">Spend your harvest.</h1>
                                <Container>
                                    <Row>
                                        <Col className="headline-helpers">
                                            <h3 className="d-none d-lg-block">Make lossless growth.</h3>
                                            <h3 className="d-none d-lg-block">Harvest and spend profits.</h3>
                                            <h3 className="d-none d-lg-block">Base value untouched.</h3>
                                            <h3 className="d-none d-lg-block">Guarenteed or your money back.</h3>
                                            <h4 className="d-lg-none">Make lossless growth.</h4>
                                            <h4 className="d-lg-none">Harvest and spend profits.</h4>
                                            <h4 className="d-lg-none">Base value untouched.</h4>
                                            <h4 className="d-lg-none">Guaranteed or your money back.</h4>
                                            <Button variant="success" size="lg" className="mt-3" onClick={() => {
                                                TrackingLib.event({
                                                    category: 'CTA',
                                                    action: 'Registration',
                                                    label: 'Sign up now!'
                                                });
                                                history.push("/coming-soon/sign-up");
                                            }}>Sign up now!</Button><br />
                                            <div className="mt-2"><small className="text-muted">No contracts. Cancel anytime.</small></div>
                                        </Col>
                                        <Col>
                                            <Image 
                                                src={`${process.env.PUBLIC_URL}/images/GrowAndHarvest.gif`} 
                                                rounded 
                                                width="400"
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <Container>
                                    <h1 className="how-it-works">How GrowBevy Works</h1>
                                    <Row className="mb-5">
                                        <Col className="text-center">
                                            <h4>Choose Plant to grow in your vritual garden</h4>
                                            <Image 
                                                src={`${process.env.PUBLIC_URL}/images/plants/Apple-Growing.png`} 
                                                rounded 
                                                width="200"
                                            />
                                            <h5><small className="text-muted">You can grow multiple types of plants each with their own benefits.</small></h5>
                                        </Col>
                                        <Col className="text-center">
                                            <h4>Harvest the plant and watch its value grow</h4>
                                            <Image 
                                                src={`${process.env.PUBLIC_URL}/images/plants/Apple-Harvest.png`} 
                                                rounded 
                                                width="200"
                                            />
                                            <h5><small className="text-muted">We can also harvest your plants for you especially for large virtual gardens.</small></h5>
                                        </Col>
                                        <Col className="text-center">
                                            <h4>Redeem the harvest for extra income</h4>
                                            <Image 
                                                src={`${process.env.PUBLIC_URL}/images/plants/Apple-Harvested.png`} 
                                                rounded 
                                                width="200"
                                            />
                                            <h5><small className="text-muted">Redeem your harvest for real cash or use it to grow your virtual garden for even larger harvests.</small></h5>
                                        </Col>
                                    </Row>
                                    <h4>Cancel anytime and get your money back.</h4>
                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ span: 8, offset: 2 }} className="text-center">
                                <Container>
                                    <h1>Growth Calculator</h1>
                                    <h3>Watch your virtual garden grow and see how much you can spend once harvested.</h3>
                                    <Row className="mb-5">
                                        <Col sm={4} className="text-center graph-helpers">
                                            <h4>Plant Type</h4>
                                            <Image 
                                                src={`${process.env.PUBLIC_URL}/images/plants/${plantTypes.find((plant) => plant.cost == plantType).name}.png`} 
                                                rounded 
                                                width="200"
                                                style={{marginBottom: 20}}
                                            />
                                            <Form>
                                                <Form.Group controlId="calculator.SelectCustom">
                                                    <Form.Control as="select" custom onChange={e => onChangePlantType(e.target.value)}>
                                                        {plantTypes.map((plant) => <option key={plant.name} value={plant.cost}>{plant.name} (${plant.cost})</option>)}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Form>
                                            <Button variant="success" size="lg" className="mt-3 d-none d-md-block" style={{margin: "0 auto"}} onClick={() => {
                                                TrackingLib.event({
                                                    category: 'CTA',
                                                    action: 'Registration',
                                                    label: 'Sign up now!'
                                                });
                                                history.push("/coming-soon/sign-up-2");
                                            }}>Sign up now!</Button>
                                            <div className="mt-1 d-none d-md-block"><small className="text-muted">No contracts. Cancel anytime.</small></div>
                                        </Col>
                                        <Col sm={8} className="text-center">
                                            {calculatorData  && calculatorData.length > 0 &&
                                            <Chart
                                                width={'100%'}
                                                height={'500px'}
                                                chartType="AreaChart"
                                                loader={<div>Loading...</div>}
                                                data={[
                                                    ['Months', 'Value + Harvest']
                                                ].concat(calculatorData)}
                                                options={{
                                                    axisTitlesPosition: 'none',
                                                    tooltip: 'none',
                                                    legend: 'none',
                                                    curveType: 'function',
                                                    crosshair: { trigger: 'both', orientation: 'vertical' },
                                                    tooltip: { trigger: 'none' },
                                                    hAxis: { showTextEvery: 6 },
                                                    vAxis: { textPosition: 'none', gridlines: { count: 0 } },
                                                    // lineWidth: 25
                                                }}
                                                chartEvents={[{
                                                    eventName: "select",
                                                    callback({ chartWrapper }) {
                                                        console.log("Selected ", chartWrapper.getChart().getSelection());
                                                    }
                                                }, {
                                                    eventName: "ready",
                                                    callback: ({ chartWrapper, google }) => {
                                                        if (googleChartInitialized.current) return;
                                                        const chart = chartWrapper.getChart();
                                                        googleChartInitialized.current = chart;
                                                        google.visualization.events.addListener(chart, "onmouseover", e => {
                                                            const { row, column } = e;
                                                            console.warn("MOUSE OVER ", { row, column }, calculatorData && calculatorData[row][column]);
                                                            if (calculatorData && calculatorData[row] && calculatorData[row][column]) {
                                                                setCalculatedMonth(calculatorData[row][0]);
                                                                setCalculatedReturn(accounting.formatMoney(calculatorData[row][column]));
                                                            }
                                                        });
                                                        // google.visualization.events.addListener(chart, "onmouseout", e => {
                                                        //     const { row, column } = e;
                                                        //     console.warn("MOUSE OUT ", { row, column });
                                                        // });
                                                    }
                                                }]}
                                            />
                                            }
                                            {calculatedMonth && calculatedReturn &&
                                            <div style={{position: "absolute", top: 50, left: 50}}>
                                                <h4>In {calculatedMonth} your<br />plant and harvest is</h4>
                                                <h2>{calculatedReturn}</h2>
                                            </div>
                                            }
                                            <Button variant="success" size="lg" className="mt-3 d-md-none" onClick={() => {
                                                TrackingLib.event({
                                                    category: 'CTA',
                                                    action: 'Registration',
                                                    label: 'Sign up now!'
                                                });
                                                history.push("/coming-soon/sign-up-3");
                                            }}>Sign up now!</Button><br />
                                            <div className="mt-2 d-md-none"><small className="text-muted">No contracts. Cancel anytime.</small></div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <hr />
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col md={{ span: 6, offset: 3 }} className="text-center">
                                {successMessage ?
                                <Alert variant="success">{successMessage}</Alert>
                                : null
                                }
                                <h2 className="join-tag">With GrowBevy, watch your income grow - literally! Join the waitlist now.</h2>
                                <Row>
                                    <Col md={{ span: 10, offset: 1 }} className="text-center">
                                    <form>
                                    <InputGroup className="mb-3 mt-3">
                                        <FormControl
                                            id="waitlistEmail"
                                            placeholder="Enter your email"
                                            aria-label="Enter your email"
                                            aria-describedby="join-waitlist"
                                            value={emailAddress}
                                            onChange={e => setEmailAddress(e.target.value)}
                                        />
                                        <InputGroup.Append>
                                            <Button variant="outline-secondary" onClick={onSend}>Get Updates</Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    <small>Receive launch updates, exclusive offerings, and the latest news.<br />We will NEVER share your information.</small>
                                    <Recaptcha
                                        ref={refRecaptcha}
                                        sitekey={config.RECAPTCHA_KEY}
                                        onError={(e) => console.error("An error occurred with the ReCaptcha check.", e)}
                                        onLoaded={(e) => console.log("ReCaptcha loaded.", e)}
                                        onResolved={onResolved} />
                                    </form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <h2>FAQs</h2>
                                <Row>
                                    <Col md={{ span: 8, offset: 2 }} className="text-center">
                                        <Accordion>
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                                    How is such a harvest possible?
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body className="text-left">
                                                    GrowBevy accesses DeFi markets that are typically only in reach for technically sophisticated finance and software junkies.

                                                    We then use our knowledge to take advantage of high interest decentralized finance platforms. These earnings come back to you as cash when you redeem your harvests.

                                                    Unlike your bank, which generates minimal interest on the money in your checking or savings account, harvest can be up to 150x compared to their APY.
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                                    Why isn't everyone doing this?
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body className="text-left">
                                                        To be clear, anyone can do this in DeFi - it's a process known as yield "farming". However, existing tools are clunky, complex, or require a wealth of technical knowledge to use properly. We make earning returns in DeFi simple and fun, the way it should be.
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                                    What are the risks?
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="2">
                                                    <Card.Body className="text-left">
                                                        None. We believe in our platform so much that you can cancel your "plant" anytime and get your money back. We take all the risk, you get all the returns.
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey="5">
                                                    This sounds too good to be true. Am I right?
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="5">
                                                    <Card.Body className="text-left">
                                                        We believe in what we do and stand by our guarantee. Try us out with a starter plant - "Cherry". Cancel anytime and get your money back.
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                                    Are my harvest redemptions guaranteed?
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="3">
                                                    <Card.Body className="text-left">
                                                        Yes! Your harvest and plant worth increase at a rate of 1% per month up to 15%. We challenge you to find a bank that will or can give such returns.
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey="4">
                                                    How often can I withdraw my funds?
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="4">
                                                    <Card.Body className="text-left">
                                                        You can cancel anytime. You can withdraw or redeem your harvests anytime as well. No fees. No catch.
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col md={{ span: 8, offset: 2 }} className="text-left">
                                        <h3>Still have questions?</h3>
                                        <h5>If you have any other questions or comments, shoot us an email at hello@growbevy.com</h5>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg className="text-center">
                                <Button variant="link" onClick={() => {
                                    TrackingLib.event({
                                        category: 'CTA',
                                        action: 'App Download',
                                        label: 'Apple'
                                    });
                                    history.push("/coming-soon/app-download-apple");
                                }}><img src={`${process.env.PUBLIC_URL}/images/AppStore-Apple.svg`} className="img-fluid" style={{width: 200}} alt="Download on the App Store" /></Button>
                                <Button variant="link" onClick={() => {
                                    TrackingLib.event({
                                        category: 'CTA',
                                        action: 'App Download',
                                        label: 'Google'
                                    });
                                    history.push("/coming-soon/app-download-google");
                                }}><img src={`${process.env.PUBLIC_URL}/images/AppStore-Google.svg`} className="img-fluid" style={{width: 200}} alt="Get it on Google Play" /></Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        );
    }

    return (
        <div className="Home">
            {queryStringSuccess && queryStringMessage && <Alert className="mt-3" variant="success">{queryStringMessage}</Alert>}
            {renderLander()}
            {isLoading ? <PageLoader /> : null}
        </div>
    );
}