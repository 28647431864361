import React, { useRef, useState, useEffect } from "react";
import config from "../config";
import { useParams } from "react-router-dom";
import Recaptcha from 'react-invisible-recaptcha';
import { Alert } from 'react-bootstrap';
import PageLoader from "../components/PageLoader";
import "./ComingSoon.css";
import * as TrackingLib from "../libs/tracking";
import { API } from "aws-amplify";

export default function ComingSoon() {
    const [name, setName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const refRecaptcha = useRef();
    let { feature } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        TrackingLib.pageview(window.location.pathname + window.location.search);
    }, []);

    async function onResolved() {
        console.log('Recaptcha resolved with response:', refRecaptcha.current.getResponse());
        setIsLoading(true);
        API.get("globals", `/globals?contactUs=${encodeURIComponent(refRecaptcha.current.getResponse())}&name=${encodeURIComponent(name || document.getElementById("inputName").value)}&emailAddress=${encodeURIComponent(emailAddress || document.getElementById("inputEmail1").value)}&message=${encodeURIComponent("Feature: "+feature)}`);
        setSuccessMessage("Thank you for contacting us. We will be in touch as quickly as possible.");
        setName("");
        setEmailAddress("");
        setIsLoading(false);
    }

    function onSend() {
        if (emailAddress === "" || name === "") {
            alert("All fields are required.");
            refRecaptcha.current.reset();
        } else {
            refRecaptcha.current.execute();
        }
    }

    return (
        <div className="ComingSoon mt-3">
            <div className="row">
                <div className="col-md-8 offset-md-2 text-center">
                    <h2 className="display-5">
                        Hello <span className="wave" role="img" aria-label="hand-waving">👋🏾</span>! You caught us before we're ready.
                    </h2>
                    <p>We're working hard to put the finishing touches into GrowBevy. Things are going well and it should be ready to help you with your finances very soon. If you'd like us to send you a reminder when we're ready...fill in the form with your details. We will never share your information.</p>
                    <hr />
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="row">
                        <div className="col-md">
                            <h5>
                                In a nutshell...
                            </h5>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">Plant trees and grow your assets.</li>
                                <li className="list-group-item">No Contracts. Cancel anytime.</li>
                                <li className="list-group-item">Redeem for extra cash or compound assets.</li>
                                <li className="list-group-item">SSL/HTTPS Secure, PCI and HIPAA compliant.</li>
                            </ul>
                        </div>
                        <div className="col-md">
                            {successMessage ?
                            <Alert variant="success">{successMessage}</Alert>
                            : null
                            }
                            <form>
                            <div className="form-group">
                                <label htmlFor="inputName">Name</label>
                                <input type="text" className="form-control" id="inputName" onChange={e => setName(e.target.value)} value={name} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="inputEmail1">Email address</label>
                                <input type="email" className="form-control" id="inputEmail1" aria-describedby="emailHelp" onChange={e => setEmailAddress(e.target.value)} value={emailAddress} />
                                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                            </div>
                            <Recaptcha
                                ref={refRecaptcha}
                                sitekey={config.RECAPTCHA_KEY}
                                onError={(e) => console.error("An error occurred with the ReCaptcha check.", e)}
                                onLoaded={(e) => console.log("ReCaptcha loaded.", e)}
                                onResolved={onResolved} />
                            <button type="button" className="btn btn-primary" onClick={onSend}>Send</button>
                            </form>
                        </div>
                    </div>                   
                </div>
            </div>
            {isLoading ? <PageLoader /> : null}
        </div>
    );
}